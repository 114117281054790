section[data-handle="home.hero"] {
  overflow: hidden;
  padding-top: 0;
  margin-top: -6rem;
  position: relative;
}

div[data-handle="home.hero.submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
}

#EnergiizeHeroSection {
  @media (max-width: 991px) {
    .EnergiizeHero {
      align-self: flex-start;
    }
  }
}

.HomeHeroSubmit {
  position: relative;
  z-index: 1;
  border-radius: 4.75rem;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: #4f4f4f;
  font-size: 1rem;
  transition: transform ease 0.4s, opacity ease 0.4s;
  opacity: 0;
  transform: translateX(100%);

  &[data-animate="true"] {
    transform: translateX(0);
    opacity: 1;
  }

  a,
  a:link,
  a:visited,
  a:active {
    color: #438269;
    text-decoration: none;
  }

  @media screen and (min-width: 1440px) {
    margin-left: 20%;
    margin-right: 0;
  }

  @media screen and (min-width: 960px) and (max-width: 1440px) {
    margin-left: 25%;
    margin-right: 0;
  }


  @media screen and (min-width: 960px) {
    border-radius: 4.75rem 0 0 4.75rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.75rem;
    margin-bottom: 2.5rem;
  }

  &--container {
    position: relative;
  }
}

.images-cluster {
  position: absolute;
  z-index: -1;
}

div[data-handle="home.hero.incentives"] {
  @media screen and (min-width: 1024px) {
    margin-left: 8%;
  }

  @media screen and (min-width: 1440px) {
    margin-left: 0;
  }
}
