.ircPage {
    #ircAccordion {
        .top_bar {
            min-height: 70px;
            background: #ffffff;
            box-shadow: 0px 4px 23px 8px rgba(0, 0, 0, 0.14);
            border-radius: 11px;
            margin: 25px 0px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
        }
        .accordion_title {
            font-family: Montserrat !important;
            font-weight: bold !important;
            font-size: 18px !important;
            line-height: 150%;
            letter-spacing: 0.15px;
            width: 100;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .top_space {
            margin-top: 20px;
        }
        .accordion_content {
            display: flex;
            align-items: center;
            color: $engergize-blue;
        }

        .download {
            text-transform: capitalize;
        }
    }
}