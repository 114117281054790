.CorePrinciplesListItem {
  &--active &__title,
  &--active &__content {
    font-weight: 600;
  }

  &__wrapper,
  &__content,
  &__title {
    color: inherit;
  }

  &__title {
    position: relative;
  }

  &__title__underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    width: 0;
    transition: width ease 0.4s;
    color: inherit;
    background-color: currentColor;
  }

  &--active &__title__underline {
    width: 100%;
  }
}
