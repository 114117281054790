.Tabs {
  &__bar {
    margin-top: 1.875rem;
    border-bottom: 1px solid #000;
  }
}

.Tab {
  &__button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    color: #828282;
    font-size: 1.125rem;
    font-weight: bold;
    padding-bottom: 0.875rem;
    padding-left: 1.625rem;
    padding-right: 1.625rem;
    position: relative;
    transition: color ease .4s;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      content: '';
      display: block;
      height: 5px;
      background-color: transparent;
      transition: background-color ease .4s;
    }

    &[data-active="true"] {
      color: #314962;

      &:after {
        background-color: #314962;
      }
    }
  }
}
