.NewsCarousel {
  .alice-carousel__dots {
    margin: 2rem 0 0;
  }

  .alice-carousel__dots-item:not(.__custom) {
    height: 1rem;
    width: 1rem;
    opacity: 25%;
    background-color: #ffffff;
    transition: opacityt ease 0.4s;
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    opacity: 100%;
  }

  .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 0.5rem;
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    transform: none;
  }

  &.NewsCarousel--sm {
    & .NewsCarouselItemWrapper {
      margin: 0 1.5rem;
    }

    & .NewsCarousel__button {
      &[data-direction="previous"] {
        left: -1.5rem;
      }
      
      &[data-direction="next"] {
        right: -1.5rem;
      }
    }
  }
}

.NewsCarousel__section {
  background: linear-gradient(77.72deg, #438269 -5.26%, #314962 15.85%, #5187BF 92.36%);
  padding: 45px 0;
  position: relative;
  width: 100%;

  &__boundary {
    margin: auto;
    max-width: 80%;
  }

  &--intro {
    margin-bottom: 45px;
    color: white;
  }
}

.NewsCarousel__button {
  position: absolute;
  top: 50%;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin-top: -2rem;
  transform: translateY(-64px);
  height: 64px;
  width: 64px;
  overflow: hidden;

  &[disabled] {
    pointer-events: none;
    opacity: 50%;
  }

  &[data-direction="previous"] {
    left: 5rem;
  }

  &[data-direction="next"] {
    right: 5rem;
  }
}

.NewsCarouselItemWrapper {
  margin: 0 9rem;

  &--sm {
    margin: 0 1.5rem;

    & .NewsCarousel__button {
      left: -1rem;
      right: -1rem;
      background-color: green;
    }
  }

  & a {
    color: inherit;
  }
}