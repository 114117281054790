.ContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;

  &__boundary {
    width: 80%;
    max-width: 80%;

    @media screen and (min-width: 1024px) {
      width: 90%;
      max-width: 90%;
    }

    @media screen and (min-width: 1440px) {
      /**
      /* Doing this to match the padding we currently have
      /* on the site sections, so that things are aligned
      /* on wider screens, we may want to change that later
      /* so that it is more flexible, using % maybe?
      **/
      padding-left: 120px;
      padding-right: 120px;

      width: 100%;
      max-width: 100%;
    }
  }
}
