%cta_button {
    background-color: $main-orange;
    color: $engergize-blue;
    font-family: Montserrat;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.2em;
    padding: 0.875rem 1.25rem;
    line-height: 1.285;
}

button #cta_button,
#cta_button {
    @extend %cta_button;
}

.headerButton,
.planningForSuccessContainer .cards_with_linkouts a,
.cta_button a,
.MuiButton-label a {
    text-decoration: none;
    text-transform: uppercase;
    color: $engergize-blue;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.input {
    background: white;
    border-radius: 20px 0px 0px 20px;
    border: none;
    width: 200px;
    height: 50px;
    margin-right: -30px !important;
}
#margin_right{
    margin-right: -30px;
}
.MuiButtonBase-root{
    background-color: $main-orange;
    font-family: Montserrat;
    height: 50px;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
}

.form_label{
    color: white;
    font-weight: bold !important;
    font-size: 18px;
}
