.BoxWithOffBg__content img {
  &.ImageText__img {
    display: block;
    margin: auto;
    object-fit: cover;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.ImageText {
  position: relative;
  
  &__img-container {
    overflow: hidden;
  }

  &__title {
    font-weight: 600;
  }

  &__content {
    position: relative;
    padding: 0.75rem 1.5rem;
    box-sizing: content-box;
    min-height: 0px;
    transition: height ease 0.4s;
    will-change: height;
    overflow: hidden;

    &[role="button"] {
      cursor: pointer;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;

    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }

  &__expandable-content {
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 0.75rem 1.5rem;

    &--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
    }

    &__content {
      overflow: hidden;
      animation-duration: 0.6s;
      animation-name: expand;
      transition: max-height 0.6s;

      &[data-open="false"] {
        max-height: 0;
      }
      &[data-open="true"] {
        max-height: 500px;
      }

      @keyframes expand {
        from {
          max-height: 0;
        }

        to {
          max-height: auto;
        }
      }
    }

    &__content,
    &__content * {
      white-space: normal;
    }

    &__icon {
      transition: transform ease 0.4s;

      &[data-open="true"] {
        transform: rotate(180deg);
      }
    }
  }

  &--link {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & .GradientBg a,
  & .GradientBg a:link,
  & .GradientBg a:hover,
  & .GradientBg a:visited {
    color: #fff;
  }
}
