#EnergiizePickFunding {
    @media(max-width:768px) {
    }
}

.engergiizeFunding{
    &Body{
        background: #fff;
        border-radius: 0px 220px 0px 0px;
        background-image: url('../../assets/EnergiizeAssets/water-mark-e.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0 -4%;

        @media only screen and (min-width: 375px) {
            background-position: 0 -6%;
        }

        @media only screen and (min-width: 425px) {
            background-position: 0 -10%;
        }

        @media only screen and (min-width: 768px) {
            background-position: 0 -46%;
        }

        @media only screen and (min-width: 1024px) {
            background-position: 0 -870%;
        }

        @media only screen and (min-width: 1440px) {
            background-position: 0 150%;
        }

        @media only screen and (min-width: 2560px) {
            background-position: 0 110%;
        }
    }
}


.energiiza_pick_funding{
    min-height: 700px;
    padding: 100px 100px 20px 100px;
    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #314962;
        padding-bottom: -30px;
        text-align: left;
        transition: ease-in all 0.8s;
        transform: translateX(999px);
    }
    .active {
        transform: translateX(0px);
        text-decoration: underline;
        text-underline-offset: 10px;
        text-decoration-color: $main-orange;
        text-decoration-thickness: 5px;
    }
    .paragraph {
        font-family: 'Montserrat';
        font-size: 20px;
        text-align: left;
    }
}
.subscribe_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.2;
    padding-top: 50px;
    padding-bottom: 50px;

    @media screen and (min-width: 960px) {
       text-align: left;
       justify-content: flex-end;
    }

    @media(max-width:991px) {
        width:100%;
    }

    .cta_button_container{
        margin: 4em 0 2em;

        @media screen and (min-width: 960px) {
           margin: 0;
        }
    }


    .title{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 1.5rem;
        color: $engergize-blue;
    }

    @media(max-width:960px) {
        .cta_button_container{
            display: flex;
            align-items: center;
            justify-content: center;

        }
    }
}
