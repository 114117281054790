.Step {
  &,
  &[class^="MuiBox"] {
    margin-top: 2rem;
  }

  &[data-variant="green"] {
    background-color: #e5eeea;

    .Step__number {
      background-color: #438269;
    }
  }

  &[data-variant="yellow"] {
    background-color: #faeee1;

    .Step__number {
      background-color: #e39f5a;
    }
  }

  padding: 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  @media (max-width: 500px) {
    display: block;
  }

  &__number,
  &__number.MuiTypography-body1 {
    color: #ffffff;
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
  }
  &__title[class^="MuiTypography"] {
    font-weight: 700;
    @media (max-width: 500px) {
      margin: 24px 0px;
    }
  }

  &__content {
    .MuiTypography-root {
      font-size: 1rem;
      opacity: 0.87;
    }
    > p {
      white-space: normal;
    }
  }
}

.Step + .Step {
  margin-top: 1.5rem;
}
