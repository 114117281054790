#VideoSection {
    background-image: url("../../assets/EnergiizeAssets/_IRCMainBanner.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    .video {
        width: 100vw;
        z-index: -10;
        position: absolute;
    }
  
}

/*
.video_container {
    width: 100%;
    margin: 50px 175px;
}

.under_video_container{
    height: 100px;
    width: 100vw;
    position: absolute;  
}
*/

.video_wrapper {
    margin: 20px;
    max-width: 700px !important; 
    /* Found that, without !important, the video takes all the horizontal space on a wide screen. Not sure why. */
}

.video_container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video_container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video_container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#under_video_text {
    
    width: 100%;
    padding: 20px;
    background: $green-to-blue-gradient;
    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}
#bold{
    font-weight: bold;
}
.left{
    display:flex;
    align-items: flex-start;
}
.center{
    display:flex;
    align-items: center;
}
#video_button{
    width: 300px !important;
    background-color: $main-orange;
    font-family: Montserrat;
    width: 173px;
    height: 50px;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
}