%base_animate_title {
    text-decoration-color: $main-orange;
    text-decoration: none;
    background-image: linear-gradient(#fff, #fff), linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255)),
        linear-gradient($main-orange, $main-orange);
    background-size: 20px 2px, 100% 0px, 0 2px;
    background-position: calc(20px * -1) 100%, 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size .9s linear, background-position .9s linear;
}

%active_animate_title {
    background-size: 20px 5px, 0 5px, 100% 5px;
    background-position: calc(100% + 20px) 100%, 100% 100%, 0 100%;
}

.animated-underline {
    @extend %base_animate_title;

    padding-bottom: 0.5rem;

    &--active {
        @extend %active_animate_title;
    }
}

.animate_title {
    @extend %base_animate_title;

    font-family: Montserrat !important;
    font-weight: bold !important;
    font-size: 24px !important;
    color: #314962;
    text-decoration-color: $main-orange;
    text-align: left;
    padding-bottom: 16px;
    margin-bottom: 30px;
}

.animate_title:hover,
.animate_title_active {
    @extend %active_animate_title;
}
