.FundingProcessItem {
  h2 p,
  h2 pre {
    display: inline-block;
    margin: 0;
  }

  &--flatLink {
    a {
      text-decoration: none;
      color: inherit;
      pointer-events: none;
    }
  }
}

div[data-handle="participate.intro.funding-process"] {
  div[class*="MuiGrid-item"] {
    display: flex;
    align-items: stretch;

    > * {
      width: 100%;
    }
  }
}
