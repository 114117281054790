.FlippingCard {
  &--container {
    height: 100%;
    width: 100%;
    perspective: 100rem;
    cursor: pointer;
  }

  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  z-index: 1;

  &__front,
  &__back {
    z-index: 1;
    position: relative;
    min-height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__back {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotateY(-180deg);
    transform-style: preserve-3d;
    transition: transform 0.8s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    z-index: 2;
  }

  &.is-flipped {
    & .FlippingCard__front {
      position: absolute;
    }

    & .FlippingCard__back {
      position: relative;
    }

    transform: rotateY(180deg);

    & + .FlippingCard__back {
      transform: rotateY(0deg);
    }
  }
}
