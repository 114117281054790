.LogosRowWithMarkdown {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: flex-start;
  }

  img {
    max-width: 100%;

    @media screen and (min-width: 800px) {
      display: block;
      width: auto;
      max-width: 25%;
      max-height: 240px;

      &[alt="Calstart"] {
        max-width: 20%;
      }

      &[alt="Tetra Tech"] {
        max-width: 35%;
        margin-top: 2em;
      }
    }
  }
}
