#column {
  width: 900px;
}
.composition {
  position: relative;
  z-index: 0;

  &__photo {
    width: 49%;

    border-radius: 2px;
    position: absolute;
    transition: all 0.2s;
    z-index: 10;
    outline-offset: 2rem;
    background: linear-gradient(0deg, rgba(253, 203, 150, 0.56), rgba(253, 203, 150, 0.56));
    background-blend-mode: overlay, normal;
    /* drop shadow */

    box-shadow: 0px 4px 15px 20px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    &--p1 {
      left: 15%;
      top: 4rem;
      z-index: 30;
    }
    &--p2 {
      right: -15%;
      top: 4rem;
      z-index: 20;
       opacity: .5;
    }
    &--p3 {
      left: 25%;
      top: 20rem;
       opacity: .5;
    }
    &--p4 {
      left: 40%;
      top: -12rem;
       opacity: .5;
    }
    &--p5 {
      left: 75%;
      top: 20rem;
       opacity: .5;
    }
    &:hover {
      box-shadow: 0 1.5rem 4rem rgba(#000, 0.5);
      z-index: 20;
      opacity: 1;
      box-shadow: 0 1.5rem 4rem rgba(#000, 0.4);
    }
  }
  &:hover &__photo:not(:hover) {
    opacity: .5;
  }
}
