.MuiPopover-root {
    & div:firs-child {
        pointer-events: none !important;
    }
}

img[alt="Energiize"][src="/static/media/EnergIIZEColorLogo.a0985f3e.svg"] {
    max-width: 100%;
    padding-right: 1em;
}

#Header {
    background-color: white;
    border-radius: 0px;
    overflow: hidden;

    .headerButton {
        margin-right: 2em;
        cursor: pointer;
        font-weight: 400;

        @media screen and (min-width: 960px) {
            font-size: 0.625rem;
            margin-right: 0.8em;
        }

        @media screen and (min-width: 1024px) {
            font-size: 0.75em;
            margin-right: 1.2em;
        }

        @media screen and (min-width: 1440px) {
            font-size: inherit;
            margin-right: 1.2em;
        }
    }
    .selectedTab {
        border-bottom: 5px solid #e39f5a;
        font-weight: bolder;
    }

    .main-menu {
        padding-left: 100px;
    }
}
.mobileNavigation {
    img {
        width: 45vw;
    }

    & .form_container {
        width: 100%;
    }

    font-family: Montserrat;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.35rem;
    text-transform: uppercase;
    border: none;

    .navigation-before-content {
        padding: 0 15px;
    }

    .NavigationFlatButton,
    .NavigationLink,
    .NavigationSubMenu,
    a {
        text-decoration: none;
        text-transform: uppercase;
        color: $engergize-blue;
        display: flex;
        justify-content: flex-start;
        height: 25%;
        align-items: center;
    }

    .MuiFilledInput-root {
        background-color: transparent;
    }
    .MuiSelect-selectMenu {
        color: #e39f5a;
    }
    .MuiSelect-iconFilled {
        margin-top: 7px;
    }
    .MuiFormLabel-root {
        color: $engergize-blue;
    }
}

.mobile-navigation-bar {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: center;
    align-items: center;
}
.MuiPaper-root {
    border-radius: 10px;
    max-width: 345;
}
