$margin: 12%;
$ease: cubic-bezier(0.25, 1, 0.5, 1);

.PictureWithOffBorder {
  padding-bottom: $margin;
  padding-left: $margin;
  opacity: 0;
  transition: opacity $ease 0.4s;

  &[data-animate="true"] {
    opacity: 1;
  }

  &__img-container {
    border-radius: 1rem;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fafafa;
    margin-left: $margin;
    transition: transform $ease 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-animate="true"] {
      transform: translate(-$margin, $margin);
    }
  }

  &__img {
    height: 100%;
    display: block;
    object-fit: cover;
  }

  &__svg,
  &_img {
    width: 100%;
    max-width: 424px;
  }
}
