$margin: 3.375rem;

.FramedImageFlag {
  max-width: 1095px;
  margin: 0 auto;

  &__picture-container {
    position: relative;
    z-index: -1;
  }
}

.FramedImageFlag__content {
  @media screen and (min-width: 960px) {
    &[data-reversed="false"] {
      margin-left: $margin;
    }

    &[data-reversed="true"] {
      margin-right: $margin;
    }
  }
}
