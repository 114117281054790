*[data-model="content"] {
  .BannerSection {
    a,
    a:link,
    a:visited,
    a:active,
    .animated-underline {
      color: #ffffff;
    }

    a,
    a:link,
    a:visited,
    a:active {
      font-weight: 600;
    }

    .AppButton {
      a,
      a:link,
      a:visited,
      a:active {
        color: inherit;
        font-weight: 700;
      }
    }
  }
}
