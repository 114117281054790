$radius: 8rem;

.BannerSection {
  position: relative;

  &--large {
    overflow: hidden;

    .BannerSection__img--container {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      min-width: 70%;
      min-height: 100%;
      width: auto;
    }

    .BannerSection__img {
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
    }

    .BannerSection__content {
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
      padding-left: $radius;
      width: 60%;
      margin-left: 40%;
      padding-right: 2rem;
    }
  }

  &--small {
    .BannerSection__img {
      width: 100%;
      object-fit: cover;

      &--container {
        max-height: 24rem;
        overflow: hidden;
      }
    }
  }
}
