html,
body {
  width: 100vw;
  overflow-x: hidden;
}

*,
.MuiTypography-body1,
.MuiTypography-root,
.MuiTypography-root.MuiTypography-body1 {
  font-family: Montserrat, sans-serif;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
