#EnergiizeHowToParticipate {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;

    .how_to_participate_wrapper {
        align-items: start !important;
    }

    .paragraph {
        font-size: 20px;
        font-family: Montserrat;
        text-align: left;
    }
}

.dropdown-reveal {
    background: linear-gradient(77.72deg, #314962 3.97%, #5187bf 92.36%);
    min-height: 340px;
}
.stacked_text_section {
    display: flex;
    flex-direction: column;
}
#downloadFactsheet{
    height: 45px;
    font-size: 18px;
    justify-content: flex-start;
    display: flex;
    margin-top: 10px;
    color: white;
    text-transform: capitalize;
    text-decoration: underline;
    font-weight: bold;
    font-family: 'Montserrat';
    text-align: left;
}
#download_container{
    height: 20px;
}
#downloadFactsheet{
    height: 20px;
}
.complex_text_container{
    font-family: 'Montserrat';
    font-size: 18px;
}
