.WorkshopWebinarsLibraryContainer {
    background: url("../../assets/EnergiizeAssets/watermark.svg");
    background-repeat: no-repeat;
    border-radius: 0px 0px 0px 200px;
}

.webinarCard {
    margin: 15px 15px 30px 10px;
}

.libraryContainer {
    padding-bottom: 50px;
}
