#WorkshopWebinars {
    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 143.74%;
        color: white;
        margin-bottom: 20px;
    }
    .paragraph {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: white;
        margin: 15px 0px;
        display: flex;
        align-items: center;
        overflow: visible;
    }
    .card_content {
        text-align: left;
    }
    .italisized {
        font-style: italic;
    }
    .nav_title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        height: 100%;
        margin: 35px 0px 0px 0px;
        color: white;
        font-weight: bold;
        bottom: 2rem;
        position: absolute;
    }
    .clock {
        height: 20px;
        margin-right: 5px;
    }
    .arrow_background {
        height: 55px;
        width: 55px;
        border-radius: 50%;
        background-color: $engergize-blue;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #TitleSection {
        padding-top: 50px;
        padding-bottom: 50px;
        display: flex;
        margin-bottom: 30px;
        height: fit-content;
        position: relative;
    }
}

#SubscribeBar .SubscribeSection--container {
    background-color: $secondary-blue;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
}
#page-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 39px;
    color: white;
    text-align: left;
}

.button {
    display: flex;
    justify-content: flex-end;
}
#TitleSection {
    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #314962;
        text-underline-offset: 3px;
        text-decoration: underline;
        text-decoration-color: $main-orange;
        text-decoration-thickness: 5px;
        text-align: left;
        margin-bottom: 20px;
    }
    .paragraph {
        font-size: 18px;
        color: black;
        text-align: left;
        margin: 0px;
        line-height: 150%;
        overflow: visible;
    }
    #ParagraphContent {
        height: 105px;
        overflow: scroll;
    }
}
#point_left {
    transform: rotate(-180deg);
}

#point_right {
    transform: rotate(0deg);
}
#ParagraphContent {
    height: 105px;
    overflow: scroll;
}
.noBottomPadding{
    padding-bottom: 0px !important;
}