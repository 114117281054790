.SignupBox {
  display: flex;
  flex-direction: column;
  background-color: #415c75;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;

  &__title,
  &__title[class^="MuiTypography"] {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
    margin-right: 0;

    @media screen and (min-width: 960px) {
      text-align: left;
      margin-bottom: 0;
      margin-right: 4rem;
    }
  }

  @media screen and (min-width: 960px) {
    flex-direction: row;
    padding-left: 6rem;
    padding-right: 6rem;
    border-radius: 6rem;
  }
}
