$radius: 0.75rem;
$off-bg-size: 10px;

.BoxWithOffBg {
  &__content {
    overflow: hidden;
    border-radius: $radius;

    img {
      width:100%;
      height:auto;
    }
  }

  &__GradientBg {
    border-radius: $radius;
  }

  &.BoxWithOffBg {
    position: relative;
    overflow: visible;
    border-radius: $radius;
    margin-top: $off-bg-size;
    margin-left: $off-bg-size;

    &:before {
      opacity: 0;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #438269;
      border-radius: inherit;
      z-index: -1;
    }

    &--hover:hover {
      transition: all 0.2s;
      transform: translate(0, -$off-bg-size);
    
      &:before {
        transition: all 0.2s;
        opacity: 1;
        transform: translate(-$off-bg-size, -$off-bg-size);
      }
    }
    
    &__before {
      &:before {
        transition: all 0.2s;
        opacity: 1;
        transform: translate(-$off-bg-size, -$off-bg-size)
      }
    }
  }
}
