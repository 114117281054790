#EngerizeHeroContainer {
    background: $green-to-blue-gradient;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    #EnergiizeHeroSection {
        padding-bottom: 12rem;
    }

    @media screen and (min-width: 768px) {
        #EnergiizeHeroSection {
            padding-bottom: 16rem;
        }
    }

    @media screen and (min-width: 960px) {
        #EnergiizeHeroSection {
            padding-bottom: 0;
        }
    }
}

#EnergiizeHeroSection {
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;

    #ContentSection {
        position: relative;
        z-index: 99;
        align-self: flex-start;
        margin-top: 10rem;

        @media (max-width: 991px) {
            background-color: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(7px);
            padding: 20px;
            border-radius: 15px;
            margin-top: 0;
        }
    }
}
#EngeriizeHomePage {
    background-image: url("../../assets/EnergiizeAssets/home-banner-bg.svg");
    background-repeat: no-repeat;

    min-height: 700px;
    justify-content: flex-end;
}
#ImageSection {
    margin-top: -240px;
    min-height: 420px;

    @media screen and (min-width: 991px) {
        min-height: 300px;
    }
}
.section_container {
    min-height: 600px;
}

.EnergiizeHero {
    // background: linear-gradient(77.72deg, #438269 -5.26%, #314962 15.85%, #5187bf 92.36%);

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: fit-content;
    #page-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        color: white;
        text-align: left;
        margin-bottom: 0.75em;
    }
    #page-sub-header,
    #page-top-sub-header {
        font-size: 18px;
        line-height: 143.74%;
        font-style: normal;
        font-weight: 700;
        font-family: Montserrat, sans-serif;
        text-align: left;
        color: #fff;
    }
    #page-sub-header {
        margin-bottom: 2.6em;
        font-weight: 400;
    }
    .container_text {
        margin: 5px 0px;
    }
    img {
        width: 450px;
        height: 300px;
        border-radius: 25px;
        box-shadow: 0 0 20px 20px #052d57;
    }
}

.app_ticker {
    background-color: white;
    color: $engergize-blue;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #4f4f4f;
    text-align: left;
    border-radius: 100px 0px 0px 100px;
    display: flex;
    justify-content: flex-end;
    .tick {
        height: 100%;
        align-items: center;
        display: flex;
    }
}

input {
    background: white;
    border-radius: 20px;
    border: none;
    width: 200px;
}
.bold {
    font-weight: bold !important;
}

.LogosRow {
    &--container {
        @media screen and (min-width: 800px) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    #EnergiizeSponsers &__img,
    &__img {
        @media screen and (min-width: 800px) {
            display: block;
            width: auto;
            max-width: 25%;
            max-height: 240px;

            &[alt="Calstart"] {
                max-width: 20%;
            }

            &[alt="Tetra Tech"] {
                max-width: 35%;
                margin-top: 2em;
            }
        }
    }
}
