.NewsCarouselItem {
  border-radius: 1.5rem;
  overflow: hidden;

  @media screen and (min-width: 960px) {
    width: 75%;
    margin: auto;
  }

  &__title[class^="MuiTypography"] {
    font-weight: 600;
  }

  &__title,
  &__text {
    color: #ffffff;
    font-size: 1.5rem;

    p {
      margin: 0;
    }
  }

  &__img {
    display: block;
    margin: auto;
    object-fit: cover;
    max-width: 100%;
    min-height: 100%;

    &-container {
      max-height: 10rem;
      height: 10rem;
      overflow: hidden;

      @media screen and (min-width: 426px) {
        min-height: 28rem;
      }
    }
  }

  &__content {
    background-color: #438269;
  }
}
