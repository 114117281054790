.card-container {
    width: fit-content;
}
.card-image {
    height: 220px;
    padding-top: "56.25%";
}
#EnergiizeHowToParticipate {
    .MuiPaper-root {
        border-radius: 10px;
        max-width: 345;
        box-shadow: -10px -10px $main-green;


    }
}

p {
    .card-container {
        height: 0;
        padding-top: "56.25%";
    }
}
.card_content_container {
    background: $blue-gradient;
    color: white;
    text-align: left;

    .card_title {
        font-weight: bold !important;
        color: white;
        font-family: Montserrat;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
    }
}

.no_padding {
    padding: 0px !important;
}
.horz_no_padding {
    padding: 0px 10px !important;
}

.MuiCardContent-root .card_content_container {
    padding: 0px;
}