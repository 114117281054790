.ExpandableLinksBox {
  background: #ffffff;
  box-shadow: 0px 4px 23px 8px rgba(0, 0, 0, 0.14);
  border-radius: 0.6875rem;
  overflow: hidden;
  transition: height 0.4s ease, opacity 0.4s ease;

  &[data-ready="false"] {
    pointer-events: none;
    opacity: 0;
  }

  &__header {
    cursor: pointer;
    border: none;
    outline: 0;
    display: block;
    width: 100%;
    background-color: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    transition: transform 0.4s ease;
    transform: rotate(180deg);

    &[data-open="true"] {
      transform: rotate(0deg);
    }
  }

  &__title {
    font-size: 1.125rem;
  }

  &__header,
  &__body {
    padding: 1.375rem 1rem;
  }

  &__body {
    padding-top: 0;
    transition: max-height 0.4s ease, transform 0.4s ease, opacity 0.4s ease;

    pre {
      margin: 0;
    }

    a {
      display: block;
      padding: 1rem 0;
      font-weight: 600;

      &,
      &:active,
      &:visited,
      &:link {
        color: #314962;
        text-decoration: none;
      }
    }

    .Link.Pdf {
      background-image: url("../../assets/icons/download-icon.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 2.5rem;
    }

    &[data-open="false"] {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
  }
}
