.Tile {
  position: relative;
  background-color: #fff;
  box-shadow: 0px -1px 47px rgba(0, 0, 0, 0.13);

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: inherit;
    background-color: #438269;
    z-index: -1;
    transform: translate(12px, -10px)
  }
}
