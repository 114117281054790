.ImagesCube--container {
  display: flex;
  align-items: center;
  justify-content: center;

  .ImagesCube__scene {
    @media screen and (min-width: 960px) {
      margin-top: -6rem;
    }

    @media screen and (min-width: 1440px) {
      margin-top: -12rem;
    }

    box-shadow: inset 0px 4px 65px 60px rgb(0 0 0 / 20%),
      0px 4px 60px 30px rgb(0 0 0 / 20%);
    border-radius: 4rem;
  }
}
