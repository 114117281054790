.PartnerTileTitle {
  &,
  &[class^="MuiTypography"] {
    font-weight: 600;
    text-align: center;
  }
}

.PartnerTileBack {
  &__text {
    text-align: center;
  }
}
