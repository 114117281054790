#Resources {
    padding-top: 50px;
    padding-bottom: 50px;

    .accordion {
        font-size: 24px;
    }
}

.accordion{
    font-size: 24px;
}