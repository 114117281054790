.PlanningBox {
  background-color: #e4ecf3;
  border-radius: 0.6875rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }

  &__img-container {
    display: none;

    @media screen and (min-width: 768px) {
      width: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: 100%;
        min-width: 150px;
        height: auto;
        padding: 10px;
      }
    }
  }

  &:visited,
  &:link,
  &:active,
  &:hover {
    color: #828282;
    text-decoration: none;
    line-height: 143.7%;
  }

  &__title,
  &__title[class^="MuiTypography"] {
    color: #314962;
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;
  }

  &__content {
    display: flex;
    align-items: flex-start;

    @media screen and (min-width: 768px) {
      margin-left: 2.5rem;
    }
  }

  &__arrow {
    flex-grow: 1;
  }
}
