$blue: #E5EDF5;
$green: #DEE7E3;

.SmoothBg {
  position: relative;
  overflow: visible;
  z-index: 0;

  & + * {
    position: relative;
    z-index: 2;
    margin-top: 1rem;
  }

  &__top,
  &__bottom {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    box-sizing: content-box;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      z-index: -1;
    }

    &:before {
      background-color: #fff;
      height: 50%;
    }

    &:after {
      height: 2px;
    }

    [data-variant="blue"] &:after {
      background-color: $blue;
    }
    [data-variant="green"] &:after {
      background-color: $green;
    }
  }

  &__top:after,
  &__top:before {
    bottom: 0;
  }

  &__bottom:after,
  &__bottom:before {
    top: 0;
  }

  &__content-container {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -2;
    }

    [data-variant="blue"] &:before {
      background-color: $blue;
    }
    [data-variant="green"] &:before {
      background-color: $green;
    }
  }
}
