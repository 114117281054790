.GradientBg {
  background: linear-gradient(77.72deg, #314962 3.97%, #5187bf 92.36%);

  &[data-kind="secondary"] {
    background: linear-gradient(
      77.72deg,
      #438269 -5.26%,
      #314962 15.85%,
      #5187bf 92.36%
    );
  }

  &[data-kind="tertiary"] {
    background: linear-gradient(264.41deg, #314962 30.78%, #438269 112.25%);
  }

  &.GradientBg,
  &.GradientBg > * {
    color: #fff;
  }
}
