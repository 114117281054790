$button-size: 54px;

.alice-carousel {
  margin-left: 1.25rem;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  position: absolute;
  top: 50%;
  padding: 0;
  width: 54px;
  height: 54px;
  box-sizing: content-box;
}

.alice-carousel__prev-btn {
  left: 0;
  transform: translateX(-125%);

  @media screen and (min-width: 1440px) {
    transform: translateX(-100%);
    padding-right: 2.5rem;
  }
}

.alice-carousel__next-btn {
  right: 0;
  transform: translateX(50%);

  @media screen and (min-width: 1440px) {
    transform: translateX(100%);
  }
}

.alice-carousel__stage-item {
  > .ItemContainer {
    padding: 0 2.5rem 0 0;
  }
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.NavButton {
  border-radius: 50%;
  padding: 0;
  min-width: $button-size;
  min-height: $button-size;
}

.alice-carousel {
  max-width: 1440px;
  margin: 0 auto;
}

.jsx-parser {
  .AppButton {
    white-space: pre-line;
  }
  * {
    white-space: normal;
  }
}
