#EnergiizeSponsers{
    padding-top: 60px;
    padding-bottom: 100px;

    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 2;
        color: #314962;
        padding-bottom: -30px;
        text-align: left;
        transition: ease-in all 0.8s;
        transform: translateX(999px);
    }
    .active {
        transform: translateX(0px);
        text-decoration: underline;
        text-underline-offset: 10px;
        text-decoration-color: $main-orange;
        text-decoration-thickness: 5px;
    }
    img {
        width:100%;
        height:auto;
    }
}
