div[data-handle="irc.hero.video"] {
  iframe {
    max-width: 700px;
    width: calc(100% - 40px);
    min-height: calc(100% - 40px);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 20px auto;
    bottom: 0;
  }
}

section[data-handle="irc.planning-for-success"] {
  background: url("../../../assets/EnergiizeAssets/EnergizeIconLargeE_seethrough_ultra.svg")
    white;
  background-repeat: no-repeat;
  background-position-y: -720px;
}

div[data-handle="irc.planning-for-success.infrastructure-insite"] {
  img {
    min-width: 180px;
    margin: 0 -30px 0 0;
  }
}
