$main-orange : #EDA65A;
$engergize-blue: #314962;
$green-to-blue-gradient: linear-gradient(77.72deg, #438269 -5.26%, #314962 15.85%, #5187bf 92.36%);
$blue-gradient: linear-gradient(77.72deg, #314962 3.97%, #5187BF 92.36%);
$green: #4F4F4F;
$main-green: #438269;

$blue-to-green-gradient:  linear-gradient(83.71deg, #314962 6.94%, #438269 140.62%);
$grey: #828282;
$light-blue: #e4ecf3;
$mint:#DEE7E3;
$secondary-blue: #415C75;
$yelow-gradient: linear-gradient(227.45deg, #EDA65A -14.24%, #438269 100%);
