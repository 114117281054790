.irc_banner_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 150px;

    @media(max-width:768px) {
        padding:25px 50px;
    }

    #sub-header {
        color: white;
        text-align: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
    }
    .MuiGrid-grid-xs-12 {
        flex-basis: 0;
        margin: 6px 0px;
    }
    #page-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        color: white;
        text-align: left;
    }
}
.EnergiizeHeroBackground {
    background: linear-gradient(77.72deg, #438269 -5.26%, #314962 15.85%, #5187bf 92.36%);
    border-radius: 200px 0px 0px 200px;

    @media(max-width:768px) {
        border-radius: 0;
    }
}

.banner_img {
    height: auto;
    width: 150%;
}
