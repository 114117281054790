.Footer {
    align-items: flex-start;
    justify-content: flex-start;
    background: $blue-to-green-gradient;
    border-radius: 30px 30px 0px 0px;
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    padding: 4.125rem 0 4.875rem 0;
    width: 100%;
    position: relative;

    .MuiTypography-body1 {
        line-height: 1.2;
    }

    &__link {
        text-decoration-line: underline;
        color: inherit;
        display: inline;
        text-transform: none;
    }

    &__content {
        display: contents;
    }

    &__block {
        &,
        &.MuiGrid-item {
            margin-bottom: 1.875rem;
        }
    }

    &__title {
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1.2em;

        &,
        &--medium {
            color: #ffffff;
        }

        &--medium {
            font-weight: 600;
            font-size: 1rem;
        }
    }

    &__logo {
        &--container {
            display: flex;
            align-items: center;

            &.MuiGrid-item {
                margin-bottom: 3.25rem;
            }
        }

        &__image {
            height: 50px;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        margin-bottom: 1em;
    }

    &__short-survey-block {
        &,
        &.MuiGrid-item {
            margin-top: 0.5rem;
        }
    }

    svg { padding-right: 10px; }
}

.icon {
    margin: 0px 5px img {
        height: 20px;
    }
}

#zappy {
    img {
        height: 20px;
        margin: 3px 0px 0px 5px;
    }
}
