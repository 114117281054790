.AppButton {
  text-transform: uppercase;
  border: none;
  letter-spacing: 0.2em;
  border-radius: 0.5rem;
  padding: 0.875rem 1.25rem;
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.285;
  cursor: pointer;

  a,
  a:link,
  a:visited,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  &[disabled] {
    pointer-events: none;
    cursor: default; 
  }
}
.AppButton[data-disabled="true"] { opacity: 0.5; pointer-events: none; }
