.WebinarCard {
  &--container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .GradientBg {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .BoxWithOffBg__content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: stretch;

      .GradientBg {
        flex-basis: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }

    .learn-button{
      text-decoration: none;
      color: #fff;
      cursor: pointer;
    }
  }
}
