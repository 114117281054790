.planningForSuccessContainer {
    background: url("../../assets/EnergiizeAssets/EnergizeIconLargeE_seethrough_ultra.svg") white;
    background-repeat: no-repeat;
    background-position-y: -720px;
    border-radius: 0px 0px 0px 200px;
}

#PlanningSection {
    min-height: 700px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 0px 0px 0px 100px;

    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #314962;
        text-underline-offset: 1px;
        text-decoration: underline;
        text-decoration-color: $main-orange;
        text-decoration-thickness: 5px;
        text-align: left;
    }
    .paragraph {
        font-family: "Montserrat";
        font-size: 18px;
        text-align: left;
    }
    .cards_with_linkouts {
        height: 100%;
        /* width: 450px; */
        padding: 15px 10px;
        display: flex;
        flex-direction: row;
        background-color: $light-blue;
        border-radius: 5px;
        box-shadow: none;
        align-items: center;

        &--disabled {
            pointer-events: none;
            background-color: transparent;
        }

        @media (max-width: 600px) {
            .card_media {
                display: none;
            }
        }

        .card_content,
        a {
            text-transform: unset;
            /* height: 250px; */
            .title {
                font-family: Montserrat;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-decoration-line: underline;
                color: $engergize-blue;
                text-decoration-color: $engergize-blue;
                text-decoration-thickness: 1px;
                margin-bottom: 10px;
            }
            .paragraph {
                color: $grey;
                font-size: 14px;
                line-height: 143.74%;
                /* height: 170px; */
                overflow: visible;
                margin: 0px 0px 0px 10px;
            }
        }
        .card_action {
            align-content: flex-start;
            /* align-self: flex-end */
        }

        .arrow_icon_wrapper {
            flex-direction: column;
            align-content: flex-start;
        }

        img {
            height: fit-content;
            height: intrinsic;
            width: 150px;
            padding: 10px;
        }
    }
}

.card_action {
    height: 100%;
    display: flex;
    align-items: flex-end;
}
.underline_offset {
    text-underline-offset: 4px !important;
}

#id-InfrastructureInsite {
    width: 180px !important;
    margin: 0px -1rem;
}
