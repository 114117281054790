section[data-handle="vendor.info"] {
  background-image: url("../../../assets/backgrounds/infrastructure/e.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  z-index: 1;
  position: relative;
  margin-bottom: -6rem;


  @media screen and (min-width: 960px) {
    background-position: -6rem 0;
  }
}
section[data-handle="vendor.find"] {
    padding-top: 0px;
}

div[data-handle="vendor.want-to.application-packet"] {
    display: flex;
    justify-content: center;
}
section[data-handle="vendor.vendor-types"] {
    position: relative;
     z-index: 1; 
}

