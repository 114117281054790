$easing: cubic-bezier(0.16, 1, 0.3, 1);

.ImagesCube {
  &__cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;

    &[data-is-frozen="false"] {
      transition: transform 0.8s $easing;
    }
  }

  &__face {
    position: absolute;
    overflow: hidden;
    border-radius: 2rem;
    overflow: hidden;
    transition: opacity 0.8s $easing;

    &[data-is-current="false"] {
      opacity: 0;
    }

    img {
      display: block;
      height: 100%;
      min-width: 100%;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}
