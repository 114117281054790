.emphasized_text {
    color: $main-green;
     font-weight: bold;
    display: revert;
    margin: 0px 0px 0px 10px;
    @media(max-width:500px) {
        font-size: larger;
    }
    @media(min-width:500px) {
        font-size: xx-large; 
    }
}

.app_ticker {
    background-color: white;
    color: $engergize-blue;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #4f4f4f;
    border-radius: 100px 0px 0px 100px;
    display: flex;
    justify-content: flex-start;
    height: 100px;
    .tick {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        margin-right: -1200px;
    }
}
#TickerSection {
    justify-content: flex-end;
    padding: 20px 15px;
    align-items: center;
    z-index: 100;
    margin-right: -100px;
    transition: all 0.8s;

    @media(max-width:991px) {
        width:100%;
        margin-right:0;
        padding-right:0;
    }

    @media(min-width:500px) {
        font-size: x-large; 
    }

    .active {
        transform: none;
        // transform: translateX(50%);

        @media(max-width:991px) {
            transform: none;
        }
    }
    .tick {
        background-color: white;
        width: 100%;
        /* padding-top: 10px;
        padding-bottom: 10px; */
        //padding: 10px;
        border-radius: 50px;
    }

    h3 {
        font-weight: normal;
    }

    a {
        display:inline-block;
        text-transform: none;
        text-decoration: underline;
    }
}


.ticker_container {
    display: flex;
    flex-direction: row;
}
.time_section {
    display: flex;
    flex-direction: column;
}
.green_text {
    color: $main-green;
    font-weight: bold;
}


