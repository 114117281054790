section[data-handle="about.intro"] {
  background-image: url("../../../assets/backgrounds/infrastructure/e.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  z-index: 1;
  position: relative;
  margin-bottom: -6rem;
  padding-bottom: 6rem;

  @media screen and (min-width: 960px) {
    background-position: -6rem 0;
  }
}

@media screen and (min-width: 960px) {
  section[data-handle="about.our-core-principles"] {
    .clickable {
      width: 70%;
    }
  }
}

section[data-handle="about.our-story"] {
  position: relative;
  z-index: 1;
}
