.TitleImageBullets {
  &__image {
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;


    &-container {
      max-height: 338px;
      overflow: hidden;
      border-radius: 11px;
      display: flex;
      justify-content: center;
    }
  }
}
