#EnergiizeAbout {
    min-height: 550px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    text-align: left;
    z-index: 10;
    padding: 50px;

    @media (min-width: 768px) {
        padding: 120px;
    }

    @media (min-width: 960px) {
        padding: 100px;
    }
}

.energiize_about_body {
    background: #e5edf5;
    border-radius: 24px;
}
.energiize_about {
    display: flex;
    justify-content: center;
}
#AboutImageSection {
    padding-bottom: 0px;
}

#AboutText {
    display: flex;
    flex-direction: column;
    margin-top: 4em;

    @media screen and (min-width: 960px) {
       margin-top: 0;
    }

    @media (max-width: 991px) {
        padding-top: 0px;
        padding-left: 0px;
    }

    .paragraph {
        font-family: Montserrat;
        font-size: 20px;
    }
}
