.HorizontalStep {
  border-radius: 11px;
  background-color: #ffffff;
  overflow: hidden;

  &__step,
  &__step[class^="MuiTypography"] {
    display: block;
    height: 41px;
    width: 41px;
    background-color: #314962;
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  &__content,
  &__content[class^="MuiTypography"] {
    font-size: 1rem;
    padding-left: 0.5em;
    white-space: normal;
  }

  &__step,
  &__step[class^="MuiTypography"],
  &__content,
  &__content[class^="MuiTypography"] {
    font-weight: 600;
  }
}
